import React, { useCallback, useState } from 'react'
import plug from '/static/img/catalog/plug.svg'
import styled, { css, keyframes } from 'styled-components'
import useTogglePopup from '../../components/Modal/useTogglePopup'
import { VideoModal } from '../../components/VideoModal'
import { WithBritishBadge } from './WithBritishBadge/WithBritishBadge'
import { WithVideoBadge } from './WithVideoBadge/WithVideoBadge'
import { getAvatarUrl } from '../getAvatarUrl'
import { isBrowser } from '../root'

const blur = keyframes`
  0% {
    filter: blur(6px);
  }
  100% {
    filter: blur(2px);
  }
`

const AvatarSkeleton = styled.img.attrs((props) => ({
  src: props.src,
  alt: 'не найдено',
  loading: 'lazy'
}))`
  border-radius: 50%;
  width: inherit;
  height: inherit;
  object-fit: cover;
  filter: ${({ preload }) => preload && 'blur(6px)'};
  overflow: hidden;
  z-index: 10;

  ${({ preloadHide }) =>
    preloadHide &&
    css`
      animation: ${blur} 0.15s ease-in 1;
    `};
`
export const AvatarSizesEnum = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small'
}

export const avatarSizeMap = {
  [AvatarSizesEnum.Large]: 120,
  [AvatarSizesEnum.Medium]: 100,
  [AvatarSizesEnum.Small]: 68
}

const xsmall = 'xsmall_'
const small = 'small_'
const DEFAULT_SIZE = 57

const Wrap = styled(({ className, children, href, ...props }) =>
  href ? (
    <a
      className={className}
      href={href}
      target={props.target ?? '_self '}
      {...props}
    >
      {children}
    </a>
  ) : (
    <div className={className} {...props}>
      {children}
    </div>
  )
)`
  position: relative;

  && {
    width: ${({ size }) => `${avatarSizeMap[size] ?? size}px`};
    height: ${({ size }) => `${avatarSizeMap[size] ?? size}px`};
  }
`

export const Avatar = styled(({ className, tooltipSize, ...props }) => {
  const [load, setLoad] = useState({
    status: isBrowser ? 'loading' : 'load',
    prefix: isBrowser ? xsmall : small
  })

  const handleImageLoaded = useCallback(() => {
    if (load.status !== 'error') {
      setLoad(() => ({
        prefix: small,
        status: 'load'
      }))
    }
  }, [load])

  const handleImageErrored = useCallback(() => {
    setLoad({ status: 'error' })
  }, [])

  const { isShowing, tglPopup } = useTogglePopup()

  return (
    <Wrap
      className={className}
      href={props.href}
      onClick={(e) => {
        if (e.target.tagName === 'path' || e.target.tagName === 'svg') {
          e.preventDefault()
          tglPopup()
        } else {
          const className = e.target.className.toString()
          if (className.split('-')[0] === 'VideoBadge') {
            e.preventDefault()
            tglPopup()
          }
        }
      }}
      size={props.size ?? DEFAULT_SIZE}
      style={props.style}
      target={props.target}
    >
      <WithVideoBadge
        tooltip={{ visible: props.videoURL, tooltipSize, size: props.size }}
      >
        <WithBritishBadge
          tooltip={{ visible: props.english, tooltipSize, size: props.size }}
        >
          <AvatarSkeleton
            onError={handleImageErrored}
            onLoad={handleImageLoaded}
            preload={load.prefix === xsmall}
            preloadHide={load.prefix === small && load.status === 'load'}
            src={
              load.status !== 'error'
                ? getAvatarUrl(props.photoUrl, load.prefix)
                : plug
            }
          />
        </WithBritishBadge>
      </WithVideoBadge>
      {isShowing && (
        <VideoModal
          hide={tglPopup}
          source={props?.source}
          title={props?.name}
          videoURL={props?.videoURL}
        />
      )}
    </Wrap>
  )
})`
  position: relative;
  width: fit-content;
  height: fit-content;
`
